<template>
  <v-row class="d-flex flex-column">
    <v-app-bar color="#eeeeee" class="not-print mx-5" app flat>
      <v-toolbar-title>{{ $route.meta.name }}</v-toolbar-title>

      <template v-if="!isMobile">
        <v-spacer />

        <v-btn @click="handleExport()" class="mr-5" color="primary">
          <v-icon left>mdi-microsoft-excel </v-icon>
          Download
        </v-btn>
        <v-btn @click="$doc.print()" class="mr-2" color="primary">
          <v-icon left>mdi-printer</v-icon>
          Imprimir
        </v-btn>
      </template>
    </v-app-bar>

    <div id="newTable" ref="document">
      <v-data-table
        :headers="headers"
        :items="transactions"
        disable-sort
        :items-per-page="-1"
        hide-default-footer
      >
        <template v-slot:[`header`]="{ props: { headers } }">
          <thead class="teste">
            <tr>
              <th :colspan="headers.length">
                <h3>Relatório para fins de IR - {{ searchParams.year }}</h3>
                <h4 v-if="searchParams.professional_id">
                  Profissional: {{ searchParams.professional_name }}
                </h4>
              </th>
            </tr>
          </thead>
        </template>

        <template v-slot:[`item.month`]="{ item }">
          <span>{{ months[item.month] }}</span>
        </template>
        <template v-slot:[`item.total`]="{ item }">
          <span>{{ $format.moneyBr(item.total) }}</span>
        </template>
        <template v-if="hasData" v-slot:[`body.append`]="{ headers }">
          <tr>
            <td :colspan="headers.length">
              Total Geral
              <b>{{ $format.moneyBr(total) }}</b>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
    <v-bottom-navigation
      v-if="isMobile"
      class="not-print"
      grow
      app
      color="primary"
    >
      <v-btn
        :block="isApp"
        icon
        @click="$doc.print()"
        class="text-center"
        color="primary"
      >
        Imprimir
        <v-icon left>mdi-printer</v-icon>
      </v-btn>

      <v-btn
        v-if="!isApp"
        icon
        @click="handleExport()"
        class="text-center"
        color="primary"
      >
        download
        <v-icon left>mdi-microsoft-excel </v-icon>
      </v-btn>
    </v-bottom-navigation>
  </v-row>
</template>

<script>
import SelectProfessional from "@/components/app/forms/SelectProfessional.vue";

export default {
  components: {
    SelectProfessional,
  },
  data() {
    return {
      searchParams: this.$route.query,
      transactions: [],
      headers: [
        { text: "Mês", value: "month" },
        { text: "Total", value: "total" },
      ],
      months: {
        0: "  ",
        1: "Janeiro",
        2: "Fevereiro",
        3: "Março",
        4: "Abril",
        5: "Maio",
        6: "Junho",
        7: "Julho",
        8: "Agosto",
        9: "Setembro",
        10: "Outubro",
        11: "Novembro",
        12: "Dezembro",
      },
    };
  },

  created() {
    this.select();
  },

  computed: {
    total() {
      return this.$calc.sum(this.transactions, "total");
    },
    hasData() {
      return this.transactions.length > 0;
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    isApp() {
      return this.$webtonative.platform != "WEBSITE";
    },
  },
  methods: {
    select() {
      this.$loading.start();
      this.$http
        .index("transactions/report/monthly-billing", this.searchParams)
        .then((response) => {
          this.$loading.finish();
          this.transactions = response.transactions;
          this.dialog = false;
        })
        .catch((error) => {
          this.$loading.finish();
          this.dialog = false;
        });
    },

    handleExport() {
      this.$loading.start();
      this.$http
        .download("transactions/export/monthly-billing", this.searchParams)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `Relatório para fins de IR ${this.searchParams.year} .xlsx`
          );
          document.body.appendChild(link);
          link.click();
          this.$loading.finish();
          this.dialog = false;
        })
        .catch((error) => {
          this.$loading.finish();
          this.dialog = false;
        });
    },
  },
};
</script>
<style lang="sass"  >
#newTable
  .v-data-table .v-data-table-header tr th
    height: 25px !important
    // border: #d3d3d3 0.01px solid !important

  .v-data-table>.v-data-table__wrapper>table>tbody>tr>td
    height: 25px !important
    // border: #d3d3d3 0.01px solid !important
  .v-data-table>.v-data-table__wrapper>table>thead>tr>th
    height: 25px !important
    // border: #d3d3d3 0.01px solid !important

tbody
  tr:hover
    background-color: transparent !important

@page
  size: A4
  margin: 0.5cm
  padding: 0.5cm

@media print
  .not-print
    display: none
    visibility: hidden
  .v-main
    padding: 0px 0px 0px 0px !important
    height: 100%
</style>
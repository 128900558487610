var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"d-flex flex-column"},[_c('v-app-bar',{staticClass:"not-print mx-5",attrs:{"color":"#eeeeee","app":"","flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$route.meta.name))]),(!_vm.isMobile)?[_c('v-spacer'),_c('v-btn',{staticClass:"mr-5",attrs:{"color":"primary"},on:{"click":function($event){return _vm.handleExport()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-microsoft-excel ")]),_vm._v(" Download ")],1),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$doc.print()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-printer")]),_vm._v(" Imprimir ")],1)]:_vm._e()],2),_c('div',{ref:"document",attrs:{"id":"newTable"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.transactions,"disable-sort":"","items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('thead',{staticClass:"teste"},[_c('tr',[_c('th',{attrs:{"colspan":headers.length}},[_c('h3',[_vm._v("Relatório para fins de IR - "+_vm._s(_vm.searchParams.year))]),(_vm.searchParams.professional_id)?_c('h4',[_vm._v(" Profissional: "+_vm._s(_vm.searchParams.professional_name)+" ")]):_vm._e()])])])]}},{key:"item.month",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.months[item.month]))])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$format.moneyBr(item.total)))])]}},(_vm.hasData)?{key:"body.append",fn:function(ref){
var headers = ref.headers;
return [_c('tr',[_c('td',{attrs:{"colspan":headers.length}},[_vm._v(" Total Geral "),_c('b',[_vm._v(_vm._s(_vm.$format.moneyBr(_vm.total)))])])])]}}:null],null,true)})],1),(_vm.isMobile)?_c('v-bottom-navigation',{staticClass:"not-print",attrs:{"grow":"","app":"","color":"primary"}},[_c('v-btn',{staticClass:"text-center",attrs:{"block":_vm.isApp,"icon":"","color":"primary"},on:{"click":function($event){return _vm.$doc.print()}}},[_vm._v(" Imprimir "),_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-printer")])],1),(!_vm.isApp)?_c('v-btn',{staticClass:"text-center",attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.handleExport()}}},[_vm._v(" download "),_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-microsoft-excel ")])],1):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }